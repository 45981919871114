var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('h2', {
    staticClass: "join-title"
  }, [_vm._v("회원가입")]), _c('div', {
    staticClass: "join-container"
  }, [_c('nav', {
    staticClass: "join-step"
  }, [_c('ul', {
    staticClass: "join-step-lists"
  }, [_c('li', {
    staticClass: "list list--on"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("STEP 1")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("약관동의")])]), _c('li', {
    staticClass: "list"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("STEP 2")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("정보입력")])]), _c('li', {
    staticClass: "list"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("STEP 3")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("가입완료")])])])]), _c('terms-of-agreements', {
    ref: "terms",
    attrs: {
      "code": _vm.$route.query.code
    }
  }), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "min-w-140px min-w-lg-160px",
    attrs: {
      "outlined": "",
      "large": "",
      "color": "grey-d"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey-6--text"
  }, [_vm._v("취소")])]), _c('v-btn', {
    staticClass: "min-w-140px min-w-lg-160px",
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("계속 진행")])], 1)], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }