<template>
    <client-page>
        <section class="section">
            <v-container>
                <h2 class="join-title">회원가입</h2>

                <div class="join-container">
                    <!-- 가입 단계 표시 -->
                    <nav class="join-step">
                        <ul class="join-step-lists">
                            <li class="list list--on">
                                <span class="badge">STEP 1</span>
                                <span class="text">약관동의</span>
                            </li>
                            <li class="list">
                                <span class="badge">STEP 2</span>
                                <span class="text">정보입력</span>
                            </li>
                            <li class="list">
                                <span class="badge">STEP 3</span>
                                <span class="text">가입완료</span>
                            </li>
                        </ul>
                    </nav>

                    <!-- 약관동의 -->
                    <terms-of-agreements ref="terms" :code="$route.query.code" />

                     <div class="v-btn--group">
                        <v-btn outlined large color="grey-d" class="min-w-140px min-w-lg-160px" @click="$router.go(-1)"><span class="grey-6--text">취소</span></v-btn>
                        <v-btn large color="primary" class="min-w-140px min-w-lg-160px" @click="submit">계속 진행</v-btn>
                    </div>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import TermsOfAgreements from "@/components/client/join/terms-of-agreements.vue";

export default {
    components: {
        ClientPage,
        TermsOfAgreements,
    },
    methods: {
        submit() {
            try {
                if (this.$refs.terms.check()) {
                    this.$store.dispatch("agreements", this.$refs.terms.selected);

                    this.$router.push({
                        path: "/join/form",
                        query: this.$route.query,
                    });
                }
            } catch (error) {
                alert(error.message.toString());
            }
        },
    },
};
</script>
