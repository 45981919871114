var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-section"
  }, [_c('div', {
    staticClass: "form--primary"
  }, _vm._l(_vm.termsList, function (terms) {
    return _c('form-row', {
      key: terms._id,
      attrs: {
        "tit": terms.subject,
        "pointer": ""
      }
    }, [_c('v-card', {
      staticClass: "agreement",
      attrs: {
        "tile": "",
        "flat": "",
        "outlined": ""
      }
    }, [_c('div', {
      staticClass: "pa-14"
    }, [_c('p', {
      staticClass: "page-text--sm",
      attrs: {
        "name": "terms",
        "readonly": ""
      },
      domProps: {
        "innerHTML": _vm._s(terms.content)
      }
    })])]), _c('v-checkbox', {
      staticClass: "mt-10",
      attrs: {
        "name": "terms",
        "value": terms._id,
        "label": "개인정보 수집 및 이용에 동의합니다. (필수)",
        "hide-details": ""
      },
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    })], 1);
  }), 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }